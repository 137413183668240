<a mat-button id="back" (click)="back()">
    <fa-icon [icon]="faArrowLeft"></fa-icon> Terug naar COOK
</a>

<div class="overlay">
    <h1>{{ title }}</h1>

    <br /><br />
    <p>
        In deze handleiding wordt in het kort de functionaliteit en werking van
        de verschillende items van Cook beschreven.
    </p>
    <br /><br />

    <strong class="fontSize"><em>Menu items</em></strong
    ><br />

    <br /><br />
    <div class="overlayMenu">
        <!-- Change config -->
        <button class="fakeMatMenuItem" *ngIf="authService.id()">
            Configuraties
        </button>

        <!-- Custom Extents
        <button
            class="fakeMatMenuItem"
            *ngIf="
                configService.config() &&
                configService.config().options.customExtent
            "
        >
            Opgeslagen Locaties
        </button> -->

        <!-- Usermanual -->
        <button
            class="fakeMatMenuItem manualButton"
            *ngIf="configService.config().options.usermanual"
            matToolTip="Handleiding"
        >
            <span>Handleiding</span>
        </button>

        <!-- Admin Panel -->
        <button
            class="fakeMatMenuItem"
            *ngIf="
                authService.id() &&
                authService.hasPermission(['manager', 'admin'])
            "
        >
            Beheeromgeving
        </button>

        <!-- Settings -->
        <button
            class="fakeMatMenuItem manualButton"
            *ngIf="
                configService.config() &&
                configService.config().options.customExtent
            "
        >
            <span>Instellingen</span>
        </button>

        <!-- Account -->
        <button class="fakeMatMenuItem" *ngIf="authService.id()">
            Account
        </button>
    </div>
    <br /><br />

    <p *ngIf="authService.id()">
        <strong>• Configuraties:</strong> Onder dit keuze menu bevindt zich de
        mogelijkheid om zich te navigeren naar verschillende configuraties.<br />
    </p>
    <p
        *ngIf="
            configService.config() &&
            configService.config().options.customExtent
        "
    >
        <strong>• Opgeslagen Locaties:</strong> Hier kunt u de opgeslagen
        locaties vinden.<br />
    </p>
    <p *ngIf="configService.config().options.usermanual">
        <strong>• Handleiding:</strong> Hier bevindt u zich nu. De handleiding
        bied de gebruiker uitleg en hulp bij de functionaliteiten van de viewer.
        <br />
    </p>
    <p *ngIf="authService.hasPermission(['manager', 'admin'])">
        <strong>• Beheeromgeving:</strong> Via deze knop kan de gebruiker van de
        viewer gekozen worden.<br />
    </p>
    <p
        *ngIf="
            configService.config() &&
            configService.config().options.customExtent
        "
    >
        <strong>• Instellingen:</strong> Hier bevinden zich de opgeslagen
        locaties en custom legenda.<br />
    </p>
    <p *ngIf="authService.id()">
        <strong>• Instellingen:</strong> Hier kunt u de opgeslagen locaties en
        custom legenda herstellen.
    </p>
    <p *ngIf="authService.id()">
        <strong>• Account:</strong> Via deze knop kan de gebruiker van de viewer
        gekozen worden.
    </p>

    <p>
        <strong>›</strong> Wachtwoord wijzigen: Dit is voor het veranderen van
        uw wachtwoord
    </p>
    <p>
        <strong>›</strong> 2FA: hier kunt u een 2 factor Authentication
        instellen
    </p>
    <p>
        <strong>›</strong> Privacy verklaring: hier bevindt zich de explicatie
        van GISArts
    </p>
    <p><strong>›</strong> Uitloggen</p>
    <br /><br />

    <strong class="fontSize"><em>Zoekfunctie</em></strong
    ><br />

    <img
        src="{{
            environment.subDirectory
        }}/assets/img/handleiding/zoekfunctiefoto.jpg"
    />
    <br /><br />

    <div>
        <input id="ch" type="checkbox" />
        <p>
            Via de zoekfunctie kan er gezocht worden naar verschillende
            informatie. Door trefwoorden in te voeren kan er bijvoorbeeld
            gezocht worden naar straatnaam en huisnummer. Door op enter te
            klikken toont de kaart het gevraagde object en opent er onder in het
            scherm een informatiepaneel. Voor uitleg over het informatiepaneel
            <label for="ch" class="read-more">klik hier►</label>
            <label for="ch" class="show-less">klik hier►</label>
        </p>
        <div class="content">
            <p>
                Adres: Hier bevinden zich alle gegevens over het ingevoerde
                adres bijvoorbeeld straatnaam en huisnummer.
            </p>
            <p>
                Panden: Hier bevindt zich alle informatie over het aangevraagde
                pand bijvoorbeeld bouwjaar en pandstatus.
            </p>
            <p>
                Verblijfsobject: Hier bevinden zich de informatie over het pand
                zoals Functie van het gebouw en gebruiksoppervlakte.
            </p>
            <p>
                Percelen: Hier staat alle informatie over het pand van dezelfde
                eigenaar als het ingevoerde adres. Achter elk informatiepanel
                staat een file-logo. Door hier op te klikken kan het aangeklikt
                informatiepanel worden verstuurd/gedeeld met een ander.
            </p>
            <strong>
                <p>
                    Let wel op: Niet alle informatiepanels hierboven uitgelegd
                    zijn zichtbaar bij het ingevoerde trefwoord.
                </p>
            </strong>
        </div>
    </div>
    <br /><br />

    <strong class="fontSize"
        ><em
            >Klik op de hieronder aangegeven icoontjes voor specifieke
            uitleg</em
        ></strong
    ><br />

    <mat-tab-group #sidenavTabs class="mainTabNavigation">
        <!-- Legend -->

        <mat-tab label="Legenda">
            <ng-template mat-tab-label>
                <mat-icon matTooltip="Legenda">layers</mat-icon>&nbsp;Legenda
            </ng-template>
            <br />
            <!-- Uitleg Legenda -->
            <strong class="fontSize"><em>Legenda</em></strong
            ><br />

            <em>Voorbeeld Legenda</em>
            <br />
            <img src="{{ legendaURL }}" />
            <br /><br />

            <p>
                De legenda geeft een overzicht van alle beschikbare kaarten in
                dit portaal. Door op de tekens (›) aan de linkerzijde te
                klikken, opent u het keuze menu per kaart. Door op het vakje
                naast het pijltje te klikken, zet u de kaart aan en/of uit en
                wordt deze op het scherm getoond.
            </p>
            <br />
        </mat-tab>

        <mat-tab
            label="Filters"
            *ngIf="
                configService.config()?.tools?.filters &&
                !configService.config()?.hideLayerSwitcher
            "
        >
            <ng-template mat-tab-label>
                <mat-icon matTooltip="Filters"
                    ><fa-icon [icon]="faFilter"></fa-icon></mat-icon
                >&nbsp;Filter
            </ng-template>
            <br />
            <!-- Uitleg Filter -->
            <strong class="fontSize"><em>Filters</em></strong
            ><br />

            <em>Voorbeeld Filters</em>
            <br />

            <img
                src="{{
                    environment.subDirectory
                }}/assets/img/handleiding/filters.png"
            />
            <br /><br />

            <p>
                Naast de legenda waarin u kaarten aan of uit kunt zetten, kunnen
                er ook filters worden toegepast op de verschillende kaarten. Zo
                kunt u, doormiddel van het klikken op de filter, kiezen om de
                verschillende elementen in een kaartlaag te selecteren of te
                deselecteren.<br /><br />
            </p>
        </mat-tab>

        <!-- Print -->
        <mat-tab
            label="Print"
            *ngIf="
                configService.config()?.tools?.print?.configs?.length &&
                !configService.config()?.hideLayerSwitcher
            "
        >
            <ng-template mat-tab-label>
                <mat-icon matTooltip="Print">print</mat-icon>&nbsp;Print
            </ng-template>
            <br />

            <!-- Uitleg Print -->
            <strong class="fontSize"><em>Print</em></strong
            ><br />

            <em
                >Door het print icoontje te gebruiken maak je een print-foto van
                het gebied op uw scherm. Aan de linkerkant staan alle
                mogelijkheden. Bij titel vult u een naam in voor de print-foto.
                Daaronder kunt u de grote van de print kiezen zoals A4 of A3.
                Als volgt staat er een schuifbalk waarbij u het verlichte gebied
                kan draaien naar de gewenste positie. Daarna heeft u de
                mogelijkheid te kiezen dat de print liggend of staand wordt
                gemaakt. Door print informatie aan te vinken wordt er bij de
                definitieve print een info blokje toegevoegd waarbij de auteur,
                datum, formaat en schaal kunt zien. Zet u de print informatie
                uit is dit niet zichtbaar. Als laatste klikt u op “Maak een
                print” en kunt u de print downloaden als Afbeelding(PNG) of als
                PDF</em
            >
            <br />

            <img
                class="printenkaart"
                src="{{
                    environment.subDirectory
                }}/assets/img/handleiding/printenUpdate2023.jpg"
            />
            <br /><br />

            <p>
                Vul de opties in en print de kaart. Hieronder vind u de uitleg
                over de verschillende opties:
            </p>
            <br />

            <div class="fakeTab">
                <p>
                    <strong>• Titel:</strong> hier kunt u de naam van uw kaart
                    aangeven.<br />
                    <strong>• Layout:</strong> hier kunt u een keuze maken
                    aangaande het papierformaat.<br />
                    <strong>• Schaal:</strong> hier kunt u de schaal van uw
                    kaart aanpassen.<br />
                    <strong>• Rotatie:</strong> door het vastpakken van de bol
                    kan de kaart naar wens worden geroteerd.<br />
                    <strong>• Opslaan als:</strong> hier kunt u een keuze maken
                    in wat voor format u wilt printen.<br /><br />
                </p>
            </div>
        </mat-tab>

        <!-- Export -->
        <mat-tab
            label="Export"
            *ngIf="
                configService.config()?.tools?.downloads &&
                !configService.config()?.hideLayerSwitcher
            "
        >
            <ng-template mat-tab-label>
                <mat-icon matTooltip="Export">save_alt</mat-icon>&nbsp;Export
            </ng-template>
            <br />
            <!-- Uitleg Export -->
            <strong class="fontSize"><em>Export</em></strong
            ><br />

            <em>Voorbeeld Export</em>
            <br />

            <img
                src="{{
                    environment.subDirectory
                }}/assets/img/handleiding/export.png"
            />
            <br /><br />
            <p>
                Hier kunt u een export maken van bijvoorbeeld de drukriolering.
                U kunt hier ook exporteren van bijvoorbeeld een hele week of een
                heel jaar.
            </p>
        </mat-tab>

        <!-- Message
          <mat-tab
            label="Email">
            <ng-template mat-tab-label>
                <mat-icon matTooltip="Email">mail</mat-icon>&nbsp;E-mail
            </ng-template>
            <br />
            <message></message>
        </mat-tab>-->

        <!-- Query -->
        <mat-tab
            label="Query"
            *ngIf="
                configService.config()?.tools?.query &&
                !configService.config()?.hideLayerSwitcher
            "
        >
            <ng-template mat-tab-label>
                <mat-icon matTooltip="Query builder">build</mat-icon>&nbsp;Query
                builder
            </ng-template>
            <br />
            <!-- Uitleg query builder -->

            <strong class="fontSize"><em>Query builder</em></strong
            ><br />

            <img
                style="float: left"
                src="{{
                    environment.subDirectory
                }}/assets/img/handleiding/querybuilder.png"
            />
            <p>
                Middels de query builder is het mogelijk om een bepaalde
                selectie van elementen op te lichten. In het bovenste invulveld
                kunt u een kaartlaag selecteren. Onder de kaartlaag kunt u het
                gene dat u wilt oplichten selecteren. U kunt de voorwaarde ook
                uitvoeren als er geen gebied is geselecteerd. Met de knop
                "Selecteer een gebied" kunt u gebied selecteren waar de query op
                wordt uitgevoerd. Bij het invulveld "Veld" kunt u een element
                selecteren waar u op wilt gaan filteren.
            </p>

            <table style="font-size: 18px; float: left">
                <tr>
                    <td>
                        <strong>• =:</strong> Selecteerd de waardes die exact
                        het zelfde zijn
                    </td>
                </tr>

                <tr>
                    <td>
                        <strong>• &lt;&gt;:</strong> Selecteerd de waardes die
                        niet het zelfde zijn
                    </td>
                </tr>

                <tr>
                    <td>
                        <strong>• &lt;:</strong> Selecteerd de waardes die
                        kleiner zijn
                    </td>
                </tr>

                <tr>
                    <td>
                        <strong>• &gt;:</strong> Selecteerd de waardes die
                        groter zijn
                    </td>
                </tr>

                <tr>
                    <td>
                        <strong>• &lt;=:</strong> Selecteerd de waardes die
                        kleiner zijn of gelijk zijn
                    </td>
                </tr>

                <tr>
                    <td>
                        <strong>• &gt;=:</strong> Selecteerd de waardes die
                        groter zijn of gelijk zijn
                    </td>
                </tr>

                <tr>
                    <td>
                        <strong>• like:</strong> Selecteerd de waardes die het
                        zelfde zijn of vergelijkbaar zijn
                    </td>
                </tr>

                <strong class="fontSize"><em>Query voorbeelden</em></strong
                ><br />
            </table>

            <img
                src="{{
                    environment.subDirectory
                }}/assets/img/handleiding/querybuilder-example1.png"
            />
            <p>
                Hier boven is een voorbeeld voor als je bijvoorbeeld alle
                gebouwen wilt selecteren die vanaf het jaar 1995 en tot en met
                2000 zijn gebouwd.
            </p>
            <br />
            <img
                src="{{
                    environment.subDirectory
                }}/assets/img/handleiding/querybuilder-example2.png"
            />
            <p>
                Hier boven is een voorbeeld voor als je bijvoorbeeld alle
                gebouwen wilt selecteren die in 1995 en 2000 zijn gebouwd.
            </p>
        </mat-tab>
    </mat-tab-group>

    <!--Knoppenbalk-->

    <strong class="fontSize"><em>Knoppenbalk</em></strong
    ><br />

    <p>
        Hieronder volgt een korte omschrijving van alle functionaliteiten van de
        knoppenbalk.<br />
    </p>

    <table style="font-size: 18px">
        <div>
            <tr>
                <td><fa-icon [icon]="faPlus"></fa-icon></td>
                <td>
                    <strong>• Nieuw:</strong> Klik om een nieuw graf te tekenen.
                </td>
            </tr>
        </div>
        <div>
            <tr>
                <td><fa-icon [icon]="faPencil"></fa-icon></td>
                <td>
                    <strong>• Bewerken:</strong> Klik op een graf om deze te
                    kunnen bewerken.
                </td>
            </tr>
            <br />
        </div>
        <div>
            <tr>
                <td><fa-icon [icon]="faArrowLeft"></fa-icon></td>
                <td>
                    <strong>• Vorig kaartbeeld:</strong> Klik op deze button om
                    terug te gaan naar de vorige kaart.
                </td>
            </tr>
            <tr>
                <td><fa-icon [icon]="faArrowRight"></fa-icon></td>
                <td>
                    <strong>• Volgend kaartbeeld:</strong> Klik op deze button
                    om terug te gaan naar de volgende kaart.
                </td>
            </tr>
            <tr>
                <td><fa-icon [icon]="faExpandArrowsAlt"></fa-icon></td>
                <td>
                    <strong>• Volledig kaartbeeld:</strong> Klik op deze button
                    om naar het volledig kaartbeeld tezoomen.
                </td>
            </tr>
            <tr>
                <td><fa-icon [icon]="faCrosshairs"></fa-icon></td>
                <td>
                    <strong>• GPS:</strong> Klik op de button om uw huidige
                    locatie op te vragen. Om deze locatie te kunnen zien zal de
                    Locatietoegang, wordt weergegeven in een pop-up,
                    geaccepteerd moeten worden.
                </td>
            </tr>
            <br />
        </div>

        <div>
            <tr>
                <td><fa-icon [icon]="faMousePointer"></fa-icon></td>
                <td>
                    <strong>• Informatie:</strong> Klik in de kaart om
                    informatie op te vragen van objecten.
                </td>
            </tr>
            <tr>
                <td><fa-icon [icon]="faVectorSquare"></fa-icon></td>
                <td>
                    <strong>– Informatie oppervlak:</strong> Teken een polygoon
                    in de kaart om informatie op te vragen over objecten.
                </td>
            </tr>
            <tr>
                <td><fa-icon [icon]="faDrawCircle"></fa-icon></td>
                <td>
                    <strong>– Informatie cirkel:</strong> Trek een cirkel in de
                    kaart om informatie op te vragen over het gevraagde gebied.
                </td>
            </tr>
            <tr>
                <td><fa-icon [icon]="faTimes"></fa-icon></td>
                <td>
                    <strong>– Deselecteer alles:</strong> Hef de selectie op.
                </td>
            </tr>
            <br />
        </div>

        <div>
            <tr>
                <td><fa-icon [icon]="faStreetView"></fa-icon></td>
                <td>
                    <strong>• Streetsmart:</strong> Icoontje toevoegen (<fa-icon
                        [icon]="faStreetView"
                    ></fa-icon
                    >) U zoomt in naar de gewenste gebied en klikt met
                    streetsmart op de gewenste plaats. In een ander tabblad
                    wordt een streetviewfoto van het gekozen gebied weergegeven.
                </td>
            </tr>
            <br />
        </div>
        <div>
            <tr>
                <td><fa-icon [icon]="faDove"></fa-icon></td>
                <td>
                    <strong>• Oblique:</strong> icoontje toevoegen (<fa-icon
                        [icon]="faDove"
                    ></fa-icon
                    >) U zoomt in naar het gewenste gebied en klikt met Oblique
                    op de gewenste plek. In een ander tabblad wordt een
                    luchtfoto van de gekozen plek weergegeven.
                </td>
            </tr>
            <br />
        </div>
        <div>
            <tr>
                <td><fa-icon [icon]="faGlobe"></fa-icon></td>
                <td>
                    <strong>• 360 Viewer:</strong> Toont een nieuw venster met
                    een 360 graden viewer, dit is een interactieve foto waarbij
                    je zelf kunt bepalen welke richtig je op kijkt.
                </td>
            </tr>
            <br />
        </div>

        <div>
            <tr>
                <td><mat-icon>timeline</mat-icon></td>
                <td>
                    <strong>• Meettool lijn:</strong> Meet de afstand in de
                    kaart. Doormiddel van dubbelklikken eindigt u de meting. Als
                    je SHIFT inhoud kan je zo in een lijn een oppervlakte
                    tekenen.
                </td>
            </tr>
            <tr>
                <td><fa-icon [icon]="faVectorSquare"></fa-icon></td>
                <td>
                    <strong>• Meettool oppervlakte:</strong> Meet oppervlakte in
                    de kaart. Doormiddel van dubbelklikken eindigt u de meting.
                </td>
            </tr>
            <tr>
                <td><fa-icon [icon]="faTimes"></fa-icon></td>
                <td>
                    <strong>– Meting verwijderen:</strong> Klik de metingen aan
                    die u wilt verwijderen.
                </td>
            </tr>
            <br />
        </div>

        <div>
            <tr>
                <td><fa-icon [icon]="faMapMarkedAlt"></fa-icon></td>
                <td>
                    <strong>• Coördinaatprikker:</strong> prikt een coördinaat
                    in de kaart. Boven in het scherm verschijnt de coördinaat
                    met daarlangs een kopieer knop.
                </td>
            </tr>
        </div>
        <div>
            <tr>
                <td><fa-icon [icon]="faSearchLocation"></fa-icon></td>
                <td>
                    <strong>• Zoom naar coördinaat:</strong> Zoomt naar het
                    ingevulde coördinaat.
                </td>
            </tr>
            <br />
        </div>

        <div>
            <tr>
                <td><fa-icon [icon]="faMapPin"></fa-icon></td>
                <td>
                    <strong>• Locatie opslaan:</strong> Sla uw favorieten
                    locatie op. Door in te zoomen op de gewenste plek. Druk op
                    de locatie opslaan knop en geef een naam aan de locatie.
                    Voor meer informatie over Locaties opslaan Klik hier ›
                </td>
            </tr>
            <br />
        </div>

        <div>
            <tr>
                <td><fa-icon [icon]="faCircle"></fa-icon></td>
                <td>
                    <strong>– Redline punt:</strong> Teken een of meerdere
                    munten op de kaart door met de linkermuisknop op de gewenste
                    plek te klikken.
                </td>
            </tr>
            <tr>
                <td></td>
                <td>
                    <strong>– Redline lijn:</strong> Teken een of meerdere
                    lijnen op de kaart. Zodra u met de linkermuisknop op de
                    kaart klikt volgt de lijn uw muis door te klikken wordt de
                    lijn vast gezet en kunt u met de muis een andere kant op.
                    Door dubbel te klikken zet u de lijn definitief vast op de
                    kaart. Als je SHIFT inhoud kan je zo in een lijn een
                    oppervlakte tekenen.
                </td>
            </tr>
            <tr>
                <td><fa-icon [icon]="faVectorSquare"></fa-icon></td>
                <td>
                    <strong>– Redline vlak:</strong> Teken een vlak door middel
                    van klikken. U kunt alle hoekige vormen maken. Door het eind
                    punt met het begin punt vast te maken wordt er een vlak
                    gecreerd met binnenin een kleur.
                </td>
            </tr>
            <tr>
                <td><fa-icon [icon]="faCircle"></fa-icon></td>
                <td>
                    <strong>– Redline cirkel:</strong> Teken een cirkel door
                    middel van slepen op de kaart. Kies de gewenste grote en
                    dubbelklik om de cirkel vast te zetten.
                </td>
            </tr>
            <tr>
                <td><fa-icon [icon]="faTimes"></fa-icon></td>
                <td>
                    <strong>– Redlining verwijderen:</strong> Wist eerder
                    aangemaakte objecten door erop te klikken.
                </td>
            </tr>
            <br />
            <strong
                >Bij elke redlining heeft u de mogelijkheid onder in het scherm
                de kleur van de lijn en/of het vlak aan te passen.</strong
            >

            <br /><br />
        </div>

        <div>
            <tr>
                <td><fa-icon [icon]="faGlobe"></fa-icon></td>
                <td><strong>• Cesium:</strong> 3D kaart.</td>
            </tr>
        </div>
    </table>

    <br /><br />

    <strong class="fontSize"><em>De kaart</em></strong
    ><br />

    <img
        class="kaartupdate"
        src="{{
            environment.subDirectory
        }}/assets/img/handleiding/KaartUpdate2023.jpg"
    />
    <br /><br />

    <p>
        Het hoofdgedeelte van het scherm geeft de kaart weer. In de kaart wordt
        alle, in de legenda aangevinkte, informatie getoond. Cook start
        standaard op met de gehele gemeente. Door middel van knoppen van het
        zijpaneel en onderpaneel kunnen deze panelen worden ingeklapt, waardoor
        de weergave van de kaart wordt vergroot. Het pictogram links onder in de
        kaart biedt u de mogelijkheid om de basiskaart te veranderen. De
        pictogram onder in het midden geeft de schaal weer.
        <br /><br />
    </p>

    <strong class="fontSize"
        ><em>TOEVOEGEN VAN KML EN JSON-BESTANDEN</em></strong
    >
    <p>
        Verschillende geo-bestanden kun je makkelijk zelf toevoegen. Zo kun je
        KML en GeoJSON-bestanden tijdelijk toevoegen. Sleep het bestand in het
        kaartbeeld. COOK zal de informatie direct weergeven zodat de informatie
        bekeken kan worden, ook kunnen er printjes gemaakt worden. Het is niet
        mogelijk om een shape te raadplegen. Dit kan wel door dit bestand om te
        zetten naar een ander bestand.
    </p>

    <p>
        Indien u nog vragen en/of opmerkingen heeft wat betreft deze handleiding
        of over Cook, neem dan contact op met GISArts.<br /><br />

        E-mail: <a href="mailto:info@gisarts.nl"> info&#64;gisarts.nl </a><br />
        Telnr. <a href="tel:0485-763601"> 0485-763601 </a>
    </p>
</div>

import { Component, EventEmitter, Output } from '@angular/core';
import { ConfigService, AuthService, MapService } from '../../_services';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MapCreatorDialogComponent } from '../map-creator.component';
import { MatDialogRef } from '@angular/material/dialog';
import {
    FormGroup,
    FormControl,
    Validators,
    FormBuilder
} from '@angular/forms';
import { forkJoin } from 'rxjs';
// import GeoJSON from 'ol/format/GeoJSON';

@Component({
    selector: 'from-shape',
    templateUrl: './from-shape.component.html',
    styleUrls: ['./from-shape.component.scss']
})
export class MapFromShapeFileComponent {
    @Output() changeLoading = new EventEmitter<boolean>();

    environment: any;
    shapeFeatures: any;
    records: any;

    step = 0;

    geoJson: any;

    name: FormControl;
    resourceFormGroup: FormGroup;

    url = 'https://fme.gisarts.nl';
    version = 'v3';

    title = 'result';

    repository = 'Samples';
    workspace = 'easyTranslator_test.fmw';
    token = '';
    type: any;

    filename: any;

    error: string;

    constructor(
        public configService: ConfigService,
        public authService: AuthService,
        private http: HttpClient,
        private mapService: MapService,
        public formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MapCreatorDialogComponent>
    ) {
        this.environment = environment;
        this.name = new FormControl('', Validators.required);

        this.resourceFormGroup = this.formBuilder.group({
            name: this.name
        });

        this.http
            .get(environment.api_base_url + '/key/fme')
            .toPromise()
            .then((res: any) => {
                this.token = res;
            });
    }

    uploadListener($event: any): void {
        const input = $event.target as HTMLInputElement;

        this.handleFileUpload(input.files)
    }

    handleFileUpload(files) {
        if (files) {
            const filesArray = Array.from(files);
            const uploadObservables = [];

            const date = new Date().getTime().toString();
            this.filename = this.authService.id() +
                  '_' +
                  date;

            filesArray.forEach((file: any) => {
                const fileName = file.name;

                uploadObservables.push(this.uploadResourceFile(file, this.filename + '.' + fileName.slice(-3)));
            });

            forkJoin(uploadObservables).subscribe(responses => {
                // Alle bestanden zijn geüpload!
                this.getTransformedFile();
            }, error => {
                console.error('Er is een fout opgetreden tijdens het uploaden van de bestanden:', error);
            });
        }
    }

    onSubmit(): void {
        this.changeLoading.emit(true);

        // Required map attributes
        const body = this.resourceFormGroup.getRawValue();
        // if (this.shapeFeatures.length > 1000) {
        //     body.type = 'WebGLPoints';
        // } else {
            body.type = 'Vector';
        // }
        body.collapsed = true;
        body.visible = true;
        body.order = 50;
        body.hide = false;
        body.click = 'featureInMap';
        body.grid = {};
        body.styling = '{"color":"#f49441","lineWidth":10,"fillColor":"rgba(244, 148, 65, 0.3)","labelKey":null,"labelMaxRes":50}';
        body.source = {
            type: 'Vector',
            geojson: this.geoJson[this.filename]
        };

        this.http
            .post(`${environment.api_base_url}/maps`, body)
            .toPromise()
            .then((res: any) => {
                this.changeLoading.emit(false);

                this.bindToConfig(res.id);
            })
            .catch(err => {
                console.error(err);
                this.changeLoading.emit(false);

            });
    }

    fileReset(): void {
        this.records = [];
    }

    bindToConfig(map_id): void {
        const body = {
            map_id,
            config_id: this.configService.config().id
        };
        this.http
            .post(`${environment.api_base_url}/maps/map-to-config`, body)
            .toPromise()
            .then((res: any) => {
                this.dialogRef.close();
                this.configService.config.set(undefined);
                this.mapService.map.set(undefined);
                this.configService.loadConfiguration();
            })
            .catch(err => {
                console.error(err);
            });
    }


    uploadResourceFile(file, name) {
        let url =
            this.url +
            '/fmedataupload/' +
            this.repository +
            '/' +
            this.workspace;
        url = url + '?opt_fullpath=true&detail=high&token=' + this.token;

        var formData = new FormData();
        formData.append('file', file, name);

        return this.http
            .post(url, formData);
    }

    getTransformedFile() {
        let url =
            this.url +
            '/fmedatastreaming/' +
            this.repository +
            '/' +
            this.workspace;
        url = url + '?detail=high&token=' + this.token;
        url =
            `${url}&SourceDataset=$(FME_DATA_REPOSITORY)/Samples/${this.workspace}/admin/${this.filename}.shp`;
            url =
            `${url}&SourceDataset2=$(FME_DATA_REPOSITORY)/Samples/${this.workspace}/admin/${this.filename}`;
        url = url + '&opt_responseformat=json';
        url = url + '&SourceFormat=SHAPEFILE';
        url = url + '&GENERIC_OUT_BASE_NAME_GENERIC=' + this.title;
        url = url + '&DestinationFormat=' + 'GEOJSON';
        url = url + '&accept=contents';

        this.http
            .get(url)
            .toPromise()
            .then((response: any) => {
                this.geoJson = response;
                this.step = 1;

                // this.dialogRef.close();
            })
            .catch(error => {});
    }


    onDragOver(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    onDragLeave(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    onDrop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();

        const files = event.dataTransfer?.files;
        console.log(files)
        if (files && files.length) {
            if (files[0].name.slice(-3) === 'dwg') {
                this.handleFileUpload(files[0]);
            }
        }
    }
}

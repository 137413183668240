import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class XmlToJsonAdapterService {
    private readonly keyMap = {
        Schema: ['xsd:schema', 'schema'],
        Import: ['xsd:import', 'import'],
        ComplexType: ['xsd:complexType', 'complexType'],
        ComplexContent: ['xsd:complexContent', 'complexContent'],
        Extension: ['xsd:extension', 'extension'],
        Sequence: ['xsd:sequence', 'sequence'],
        Element: ['xsd:element', 'element'],
        SourceUrl: ['xlink:href', 'href'],
        Name: ['name', 'ows:Identifier'],
        Title: ['title', 'ows:Title'],
        ServiceException: [
            'ServiceException',
            'ows:Exception',
            'ows:ExceptionText'
        ],

        // this is for WMTS
        ServiceIdentification: ['ows:ServiceIdentification'],
        Abstract: ['ows:Abstract'],
        ServiceType: ['ows:ServiceType']
    };

    transform(xmlData: Document): any {
        return this.convertToGeneric(xmlData.documentElement);
    }

    private convertToGeneric(element: Element): any {
        let obj = {};
        // Process element attributes
        for (let i = 0; i < element.attributes.length; i++) {
            const attribute = element.attributes[i];
            obj[attribute.name] = attribute.value;
        }

        // Process child nodes
        for (let i = 0; i < element.childNodes.length; i++) {
            const child = element.childNodes[i];

            if (child.nodeType === Node.ELEMENT_NODE) {
                const childElement = child as Element;
                const genericKey = this.findGenericKey(childElement.tagName);
                const convertedChild = this.convertToGeneric(childElement);

                // Handle multiple child nodes with the same tag name
                if (obj.hasOwnProperty(genericKey)) {
                    if (!Array.isArray(obj[genericKey])) {
                        obj[genericKey] = [obj[genericKey]];
                    }
                    obj[genericKey].push(convertedChild);
                } else {
                    obj[genericKey] = convertedChild;
                }
            } else if (child.nodeType === Node.TEXT_NODE) {
                const textContent = child.textContent?.trim();
                if (textContent) {
                    // Directly assign text content to the parent element's key
                    obj[this.findGenericKey(element.tagName)] = textContent;
                }
            } else if (child.nodeType === Node.CDATA_SECTION_NODE) {
                const textContent = child.textContent?.trim();
                if (textContent) {
                    // Directly assign text content to the parent element's key
                    obj[this.findGenericKey(element.tagName)] = textContent;
                }
            }
        }

        // If the object has only one key and that key matches the element's tag name,
        // then replace the object with the text content directly
        const keys = Object.keys(obj);
        if (
            keys.length === 1 &&
            keys[0] === this.findGenericKey(element.tagName)
        ) {
            obj = obj[keys[0]];
        }

        return obj;
    }

    private findGenericKey(key: string): string {
        for (const genericKey in this.keyMap) {
            if (this.keyMap[genericKey].includes(key)) {
                return genericKey;
            }
        }
        return key;
    }
}

import { Injectable } from '@angular/core';
import { XmlToJsonAdapterService } from './XMLToJsonAdapter.service';

type Layer = {
    name: string;
    label: string;
    visible: boolean;
    layers?: Layer[];
};
type Map = {
    name: string;
    tooltip: string;
    type: string;
    collapsed: boolean;
    visible: boolean;
    order: number;
    hide: boolean;
    source: {
        serverType: string;
        type: string;
        url: string;
    };
    layers: Layer[];
};

@Injectable({
    providedIn: 'root'
})
export class GetCapabilitiesService {
    private readonly parser = new DOMParser();

    constructor(private readonly xmlToJsonAdapter: XmlToJsonAdapterService) {}

    handle(xml: any) {
        if (!this.isValidXML(xml)) {
            return {
                error: xml
            };
        }

        const xmlDoc = this.parser.parseFromString(xml, 'text/xml');
        const servertype = this.identifyOGCServerType(xmlDoc);
        console.log('servertype');
        console.log(servertype);
        const json = this.xmlToJsonAdapter.transform(xmlDoc);
        console.log(json);

        return this.convertJSONToGroupsAndLayers(json, servertype);
    }

    private convertJSONToGroupsAndLayers(json: any, serverType?: string) {
        console.log('json', json);
        const error = json?.ServiceException?.ServiceException;

        console.log('error', error);

        if (error) {
            return {
                error: error
            };
        }

        const layers: Layer[] = [];

        // json.contents is for WMTS
        const sourceMap = json?.Capability?.Layer ?? json?.Contents;
        let layerArray = [];

        if (Array.isArray(sourceMap?.Layer)) {
            layerArray = sourceMap?.Layer;
        } else if (!Array.isArray(sourceMap?.Layer)) {
            layerArray = [sourceMap?.Layer];
        } else {
            console.log('Er is geen layer array gevonden');
        }

        layerArray.forEach((l: any) => {
            if (!l) return;
            const layersInLayer: Layer[] = [];
            const name = l?.Name ?? l?.Title;
            const cleanName = this.toCleanName(name);

            if (Array.isArray(l?.Layer)) {
                l?.Layer.forEach((l: any) => {
                    const name = l?.Name ?? l?.Title;
                    const cleanName = this.toCleanName(name);

                    layersInLayer.push({
                        name: name,
                        label: cleanName,
                        visible: true
                    });
                });
            } else if (l) {
                layersInLayer.push({
                    name: name,
                    label: cleanName,
                    visible: true
                });
            }

            layers.push({
                name: name,
                label: cleanName,
                visible: true,
                layers: layersInLayer
            });
        });

        const mapName = this.getMapTitle(json);

        const map: Map = {
            name: this.toCleanName(mapName),
            tooltip:
                typeof sourceMap?.Abstract === 'string'
                    ? sourceMap.Abstract
                    : '',
            type: 'Image',
            collapsed: true,
            visible: true,
            order: 15,
            hide: false,
            source: {
                type: json?.Service?.Name ?? '',
                serverType: serverType,
                url:
                    json?.Capability?.Request?.GetCapabilities?.DCPType?.HTTP
                        ?.Get?.OnlineResource?.['xlink:href'] ?? ''
            },
            layers: layers
        };

        return {
            succes: map
        };
    }

    private toCleanName(name: string) {
        if (!name) return;

        const cleanName = name.replace(/_/g, ' ');
        const firstLetter = cleanName.charAt(0).toUpperCase();
        const rest = cleanName.slice(1).toLowerCase();
        return firstLetter + rest;
    }

    private getMapTitle(json: any) {
        let title = json?.ServiceIdentification?.Title;

        if (typeof title === 'string') {
            return title;
        }

        title = json?.Service?.Title;

        if (typeof title === 'string') {
            return title;
        }

        return 'Geen titel gevonden';
    }

    private isValidXML(content: string): boolean {
        const parsedDocument = this.parser.parseFromString(content, 'text/xml');

        // Check for parser errors (if the document contains <parsererror>)
        const parserError = parsedDocument.getElementsByTagName('parsererror');
        if (parserError.length > 0) {
            console.log('Parser error: Invalid XML', parserError);
            return false;
        }

        // Ensure there's a root element (valid XML should have a root element)
        if (
            !parsedDocument.documentElement ||
            parsedDocument.documentElement.nodeName === 'html'
        ) {
            console.log('Invalid XML: Root element missing or HTML detected');
            return false;
        }

        return true;
    }

    private identifyOGCServerType(xmlDoc: Document): string | null {
        // Check for MapServer specific clues
        const titleElement = xmlDoc.querySelector('Title');
        const abstractElement = xmlDoc.querySelector('Abstract');
        const onlineResourceElement = xmlDoc.querySelector('OnlineResource');

        if (titleElement?.textContent?.toLowerCase()?.includes('mapserver')) {
            return 'mapserver';
        }

        if (
            abstractElement?.textContent?.toLowerCase()?.includes('mapserver')
        ) {
            return 'mapserver';
        }

        if (
            onlineResourceElement
                ?.getAttribute('xlink:href')
                ?.toLowerCase()
                ?.includes('mapserv')
        ) {
            return 'mapserver';
        }

        // Check for GeoServer specific clues
        if (titleElement?.textContent?.toLowerCase()?.includes('geoserver')) {
            return 'geoserver';
        }

        if (
            abstractElement?.textContent?.toLowerCase()?.includes('geoserver')
        ) {
            return 'geoserver';
        }

        if (
            onlineResourceElement
                ?.getAttribute('xlink:href')
                ?.toLowerCase()
                ?.includes('geoserver')
        ) {
            return 'geoserver';
        }

        // If no clear clues were found, return null
        return null;
    }
}

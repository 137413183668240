import {
    Component,
    Input,
    Output,
    EventEmitter,
    signal,
    ChangeDetectionStrategy,
    OnInit,
    WritableSignal
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LegendService } from 'app/_services';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { MetadataDialogComponent } from 'app/_dialogs';
import { Vector } from 'ol/source';

@Component({
    selector: 'layer',
    templateUrl: 'layer.component.html',
    styleUrls: ['layer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * There are two kinds of layers in OpenLayers and the LayerSwitcherComponent
 * The LayerGroup, which is a group of paramLayers that are contained within one actual Layer. Using the updateLayer
 * function we can change these so the paramLayers in the group are toggled on or off. These paramLayers are toggled
 * on or off by adding/removing them from the layer itself.
 */
export class LegendLayerComponent implements OnInit {
    readonly faInfoCircle = faInfoCircle;

    readonly checked = signal(false);
    readonly hide_in_legend = signal(false);

    readonly label = signal(undefined);
    readonly name = signal(undefined);
    readonly icon: WritableSignal<SafeHtml> = signal(undefined);
    readonly metadata = signal(undefined);
    readonly metadata_url = signal(undefined);

    @Input() readonly layer: any; // The layer in the source of the map
    @Input() readonly map: any; // The map
    @Output() readonly checkEvent = new EventEmitter<boolean>();

    constructor(
        private readonly dialog: MatDialog,
        private readonly sanitizer: DomSanitizer,
        private readonly legendService: LegendService
    ) {}

    ngOnInit(): void {
        this.label.set(this.layer.label);
        this.icon.set(this.sanitizer.bypassSecurityTrustHtml(this.layer.icon));
        this.metadata.set(this.layer.metadata);
        this.metadata_url.set(this.layer.metadata_url);
        this.name.set(this.layer.name);
        this.hide_in_legend.set(this.layer.hide_in_legend);

        this.checked.set(this.layer.visible);

        // Is this layer checked? If there is no name, this isn't a checkable item, thus checked is false
        if (this.name !== undefined) {
            this.checked.set(this.layer.visible);
        }

        this.syncLayerVisibility();
    }

    toggleLayer(): void {
        this.layer.visible = !this.layer.visible;

        const map = this.legendService.findMap(this.map.id);

        if (map) {
            const source = map?.getSource();

            if (source instanceof Vector) {
                map.changed();
            }
        }
        // Only toggle if there is a name present on the layer, otherwise it's just used for label purposes
        if (this.layer.name) {
            this.syncLayerVisibility();
        }

        // add the changes to customlegend to save it later
        this.legendService.customMap[this.map.name] = this.map;
    }

    /**
     * Triggers when the checkbox is checked by a user and emits the event to the parent
     */
    checkboxChanged(event: Event): void {
        // If this item is checked now, emit an event so the map knows and can check accordingly
        this.checked.set(this.layer.visible);
        this.checkEvent.emit(this.layer.visible);
    }

    openMetadata(): void {
        this.dialog.open(MetadataDialogComponent, {
            data: {
                descriptor: this.name(),
                metadata: JSON.parse(this.metadata())
            }
        });
    }

    private syncLayerVisibility(): void {
        const isVisible = this.legendService.isLayerVisible(
            this.layer,
            this.map
        );

        if (this.layer.visible !== isVisible) {
            this.legendService.toggleLayer(this.layer, this.map);
        }
    }
}

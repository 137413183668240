import {
    ChangeDetectionStrategy,
    Component,
    EffectRef,
    OnDestroy,
    effect,
    signal
} from '@angular/core';
import { ConfigService, CqlService, SidenavService } from 'app/_services/';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';

interface FilterItem {
    active: boolean;
    color: string;
    name: string;
    title: string;
}

interface Filter {
    name: string;
    layers: string[];
    filters: FilterItem[];
    columnName: string;
    timeFilter?: {
        column: string;
        start: string;
        end: string;
    };
    collapsed?: boolean;
}

@Component({
    selector: 'filter',
    styleUrls: ['filter.component.css'],
    templateUrl: 'filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterComponent implements OnDestroy {
    readonly faAngleRight = faAngleRight;
    readonly faAngleDown = faAngleDown;
    readonly filters = signal<Filter[]>([]);
    private configSubscription: EffectRef;

    constructor(
        private readonly configService: ConfigService,
        private readonly cqlService: CqlService,
        private readonly sideNavService: SidenavService
    ) {
        this.configSubscription = effect(
            () => {
                const config = this.configService.config();
                const filters = config?.tools?.filters ?? [];
                this.filters.set(filters);

                if (filters.length > 1) {
                    return;
                }

                this.updateCql(this.filters()[0]);
            },
            { allowSignalWrites: true }
        );

        this.sideNavService.setWidth(700, 'px');
    }

    ngOnDestroy(): void {
        this.configSubscription.destroy();
    }

    toggleFilter(selectedFilter: Filter, filterIndex: number): void {
        const filter = selectedFilter.filters[filterIndex];
        filter.active = !filter.active;

        this.updateCql(selectedFilter);
    }

    updateCql(filter: Filter): void {
        const { columnName, layers } = filter;
        const notFilter = filter.filters
            .filter(f => !f.active)
            .map(f => `NOT (${columnName} = '${f.name}')`)
            .join(' AND ');

        let query = notFilter || '';

        // Check if timeFilter is defined and valid
        if (filter?.timeFilter) {
            const {
                start: timeFilterStart,
                end: timeFilterEnd,
                column: timeFilterColumn
            } = filter.timeFilter;

            const startISO = this.toISOStringWithTimezone(timeFilterStart);
            const endISO = this.toISOStringWithTimezone(timeFilterEnd);

            const timeFilterQuery = `${timeFilterColumn} >= '${startISO}' AND ${timeFilterColumn} <= '${endISO}'`;

            // Append timeFilterQuery to existing query
            query = query ? `${query} AND ${timeFilterQuery}` : timeFilterQuery;
        }

        this.cqlService.update(layers, query);
    }

    private toISOStringWithTimezone(dateString: string): string {
        const date = new Date(dateString);
        return date.toISOString();
    }

    toggleFilterCollapse(filter: Filter): void {
        filter.collapsed = !filter.collapsed ? true : !filter.collapsed;
    }
}

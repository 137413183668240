import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit
} from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';


import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'app/_services';
import { HttpClient } from '@angular/common/http';
import { TChartType } from '@cubejs-client/ngx';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'environments/environment';

import { IReportEmbedConfiguration, models } from 'powerbi-client';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.dialog.html',
    styleUrls: ['./dashboard.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardDialogComponent implements OnInit {
    environment: any;
    isQueryPresent: boolean;
    chartType: TChartType;

    reportConfig: IReportEmbedConfiguration = {
        type: 'report',
        embedUrl: "",
        tokenType: models.TokenType.Embed,
        accessToken: "",
        id: "",
        settings: {
        },
    };

    url: any;
    token: any;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected http: HttpClient,
        public configService: ConfigService,
        public dialogRef: MatDialogRef<DashboardDialogComponent>,
        private domSanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.environment = environment;

        this.http
            .get(environment.api_base_url + '/key/fme')
            .toPromise()
            .then((res: any) => {
                this.token = res;
            });
    }

    ngOnInit(): void {
        this.reportConfig.embedUrl = this.data.charts[0]?.options.url;
        this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.data.charts[0]?.options.url);
    }

    back(): void {
        this.dialogRef.close();
    }
}

<div class="p-3 h-100" *ngIf="data.type === 'powerbi'">
    <powerbi-report
        [embedConfig]="reportConfig"
        cssClassName="powerbi-report-container"
        phasedEmbedding="false"
    >
        <div style="height: 100%"></div>
    </powerbi-report>
</div>
<div class="p-3 h-100" *ngIf="data.type === 'fme'">
    <iframe
        class="powerbi-report-container"
        [src]="url"
        frameborder="0"
    ></iframe>
</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    signal,
    ChangeDetectorRef,
    NgZone
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, ConfigService } from 'app/_services';
import { environment } from 'environments/environment';

@Component({
    selector: 'login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * This component handles the login
 */
export class LoginComponent implements OnInit {
    loginPrefix = '';

    username: string;
    password: string;
    readonly env = environment;

    loading = signal(false);

    readonly hide = signal(true);
    readonly error = signal('');

    constructor(
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly http: HttpClient,
        private readonly activatedRoute: ActivatedRoute,
        private zone: NgZone,
        public readonly configService: ConfigService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        // Check if there is a session and login
        this.http
            .get(this.env.api_base_url + '/loggedin', {
                responseType: 'json',
                observe: 'response'
            })
            .toPromise()
            .then(
                response => {
                    if (response.status === 200) {
                        this.authService.id.set(response.body);
                        if (
                            this.activatedRoute.snapshot.paramMap.get('element')
                        ) {
                            this.router.navigate([
                                '/element/' +
                                    this.activatedRoute.snapshot.paramMap.get(
                                        'element'
                                    )
                            ]);
                        } else {
                            this.router.navigate(['/']);
                        }
                    }
                },
                err => {
                    if (
                        this.activatedRoute.snapshot.paramMap.get('username') &&
                        this.activatedRoute.snapshot.paramMap.get('token')
                    ) {
                        const body = {
                            username:
                                this.activatedRoute.snapshot.paramMap.get(
                                    'username'
                                ),
                            password:
                                this.activatedRoute.snapshot.paramMap.get(
                                    'token'
                                ),
                            organization: this.env.loginPrefix
                        };

                        this.http
                            .post(
                                this.env.api_base_url + '/remote-login',
                                body,
                                { responseType: 'json' }
                            )
                            .subscribe((response: any) => {
                                this.authService.setDetails(response);
                                if (
                                    this.activatedRoute.snapshot.paramMap.get(
                                        'element'
                                    )
                                ) {
                                    this.router.navigate([
                                        '/element/' +
                                            this.activatedRoute.snapshot.paramMap.get(
                                                'element'
                                            )
                                    ]);
                                } else {
                                    this.router.navigate(['/']);
                                }
                            });
                    }
                }
            );

        if (this.env.active_directory) {
            const httpParams = new HttpParams({
                fromObject: this.activatedRoute.snapshot.queryParams
            });
            console.log(httpParams);
            if (httpParams.get('code')) {
                this.http
                    .get(this.env.api_base_url + '/ad-login', {
                        responseType: 'json',
                        params: httpParams
                    })
                    .toPromise()
                    .then(
                        (response: any) => {
                            this.authService.setDetails(response);
                            this.authService.loadApp();
                        },
                        error => {
                            this.error.set(error.error);
                        }
                    );
            }
        }
    }

    login(): void {
        this.loading.set(true);
        const username = this.username.toLowerCase();

        const body = {
            username,
            password: this.password,
            organization: this.env.loginPrefix
        };

        const url = this.env.base_url + '/login';

        this.http
            .post(url, body, {
                responseType: 'json'
            })
            .toPromise()
            .then(
                (response: any) => {
                    this.loading.set(false);

                    if (response === 'already_logged_in') {
                        this.authService.loadApp();
                    }

                    if (response.two_factor) {
                        this.router.navigate(['two-factor']);
                        return;
                    }

                    this.authService.setDetails(response);

                    if (response.type === 'reset') {
                        // Indicates a succesful login that requires a password reset, send user to the password reset screen
                        this.router.navigate(['wachtwoord/verlopen']);
                        return;
                    }

                    // return true to indicate successful login
                    this.zone.run(() => this.router.navigate(['']));

                    setTimeout(() => {
                        this.authService.loadApp();
                    }, 0);

                    setTimeout(() => {
                        this.cdr.detectChanges();
                    }, 0);
                },
                error => {
                    this.loading.set(false);
                    if (
                        error.error &&
                        error.error.message ==
                            'These credentials do not match our records.'
                    ) {
                        this.error.set(
                            'Verkeerde gebruikersnaam of wachtwoord, probeer het opnieuw.'
                        );
                    }
                    // else if(error.error && error.error.error == "already_logged_in" ) {
                    //     this.error = "U bent al ingelogd.";
                    // }
                    else if (
                        error.error &&
                        error.error.error == 'user_expired'
                    ) {
                        this.error.set('Uw account is verlopen.');
                    }
                }
            );
    }

    ADLogin() {
        window.open(this.env.base_url + '/auth/redirect', '_self');
    }

    handleDataFromChild(data: any) {
        // Process the data received from the child window
        console.log(data);
    }
}

import {
    Component,
    ViewEncapsulation,
    TemplateRef,
    EffectRef,
    effect,
    OnDestroy,
    ChangeDetectionStrategy,
    // signal,
    ViewChildren
} from '@angular/core';
import {
    GridService,
    MapService,
    ConfigService,
    SidenavService
} from 'app/_services';
import { MediaDialogComponent } from './dialogs/media.dialog';
import { MatDialog } from '@angular/material/dialog';
import { faFileExport, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Style, Fill, Stroke, Circle } from 'ol/style';
import Feature from 'ol/Feature';
import { SelectExportDialogComponent } from 'app/_dialogs';
import { Geometry } from 'ol/geom';
import { VrViewerDialogComponent } from 'app/tools/vr-photo/vr-viewer/vr-viewer';
import { StyleLike } from 'ol/style/Style';
import { MatSort } from '@angular/material/sort';

// import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Subscription } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import { QueryList } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AfterViewInit } from '@angular/core';
import { EditGridDialog } from './dialogs/edit-grid.dialog';

export interface ClickItem {
    id: number;
    source: any;
    layers: GridLayer[];
    name: string;
    columnName: string;
    columns: Column[];
}

export interface Column {
    name: string;
    prop: string;
    type?: string;
    sort?: string;
    width?: number;
    cellTemplate?: TemplateRef<any>;
}

export interface GridLayer {
    title: string;
    features: Feature<Geometry>[];
}

export interface GridItem {
    columnName: string;
    name: string;
    type: string;
    graph: {
        x: number;
        type: string;
    };
    columns: Column[];
}

@Component({
    selector: 'grid',
    templateUrl: 'grid.component.html',
    styleUrls: ['grid.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridComponent implements OnDestroy, AfterViewInit {
    readonly faFileExport = faFileExport;
    readonly faAngleDown = faAngleDown;
    private readonly originalFeatures: any[] = [];
    private regularStyle: StyleLike;

    customGrids: any = [];

    private exportFeatures: any[] = [];
    private highlightedFeature: Feature<Geometry>;

    readonly chartOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        }
    };

    features = [];
    private gridConfig: GridItem[];
    readonly chartColors = ['#7DB3FF', '#49457B', '#FF7C78', 'rgb(255,205,86)'];

    private configSubscription: EffectRef;
    private _featureSubscription: Subscription;

    @ViewChildren(MatSort) sorts: QueryList<MatSort>;
    @ViewChildren(MatTable) tables: QueryList<MatTable<any>>;

    constructor(
        readonly gridService: GridService,
        readonly sidenavService: SidenavService,
        private readonly dialog: MatDialog,
        private readonly mapService: MapService,
        private readonly configService: ConfigService,
        private cdr: ChangeDetectorRef
    ) {
        this.configSubscription = effect(() => {
            const config = this.configService.config();

            if (!config) return;

            this.gridConfig = config.tools.grid ?? [];
            this.createColumns();
        });
    }

    ngAfterViewInit(): void {
        this.updateSort();
    }

    updateSort() {
        this.sorts.changes.subscribe((sorts: QueryList<MatSort>) => {
            sorts.forEach((sort, index) => {
                if (this.features[index]) {
                    this.features[index].sort = sort;
                }
            });
        });
    }

    ngOnDestroy(): void {
        this.configSubscription.destroy();
        this._featureSubscription.unsubscribe();
    }

    export(type): void {
        this.dialog.open(SelectExportDialogComponent, {
            panelClass: 'SelectExportDialogComponent',
            data: {
                features: this.exportFeatures[type.id],
                title: type.title
            }
        });
    }

    private highlightStyle() {
        // Feel free to create a better highlight styling
        return new Style({
            fill: new Fill({
                color: 'rgba(0, 51, 255, 0.5)'
            }),
            stroke: new Stroke({
                color: '#0033ff',
                width: 2
            }),
            image: new Circle({
                radius: 7,
                fill: new Fill({
                    color: '#0033ff'
                })
            })
        });
    }

    highlightFeature(feature: any): void {
        if (this.highlightedFeature) {
            this.highlightedFeature.setStyle(this.regularStyle);
        }

        this.highlightedFeature = this.originalFeatures.find(
            originalFeature => originalFeature.getId() === feature.id_
        );

        if (this.highlightedFeature) {
            this.regularStyle = this.highlightedFeature.getStyle();

            this.highlightedFeature.setStyle(this.highlightStyle());

            const geometry = this.highlightedFeature.getGeometry();
            if (geometry) {
                const view = this.mapService.map().getView();
                view.fit(geometry.getExtent(), {
                    padding: [200, 200, 300, 200],
                    maxZoom: 17,
                    duration: 2000
                });
            }
        }
    }

    openImageDialog(data): void {
        this.dialog.open(MediaDialogComponent, {
            data: {
                title: 'Afbeelding',
                imageSrc: data
            }
        });
    }

    openVideoDialog(videoSrc): void {
        this.dialog.open(MediaDialogComponent, {
            data: {
                title: 'Video',
                videoSrc: videoSrc
            }
        });
    }

    openVRDialog(imageSrc): void {
        this.dialog.open(VrViewerDialogComponent, {
            height: '80vh',
            width: '75vw',
            data: {
                imageUrl: imageSrc
            }
        });
    }

    createColumns(): void {
        // Subscribe to the features of the GridService so we get updated values once they've been pushed to the service
        this._featureSubscription = this.gridService.featuresChange.subscribe(
            (clicks: ClickItem[]) => {
                this.handleGridClick(clicks);
            }
        );
    }

    private handleGridClick(clicks: ClickItem[]): void {
        // Reset the features
        this.features = undefined;

        if (!clicks || clicks.length < 1) {
            this.gridService.openStatus.set('closed');
            return;
        }
        let sortedFeatures = undefined;
        sortedFeatures = [];
        this.exportFeatures = undefined;
        this.exportFeatures = [];

        clicks.forEach((click, index) => {
            if (click?.layers.length < 1) return;
            click.layers.forEach(layer => {
                let info: any = undefined;
                info = {};

                let combined = false;
                const combine_type = sortedFeatures.find(
                    f => f.typeName === layer.title
                );
                if (combine_type && combine_type.type === 'columns') {
                    combine_type.data = combine_type.data?.concat(
                        this.getColumnData(layer)
                    );
                    combined = true;
                }

                if (click.source && click.source.values_) {
                    const grid: GridItem[] | undefined =
                        click.source.get('grid');
                    let mapData = undefined;
                    if (grid?.length > 0) {
                        grid.forEach(g => {
                            if (
                                g.columnName.includes(',') &&
                                g.columnName.includes(layer.title) 
                            ) {
                                const combine_type = sortedFeatures.find(
                                    f => f.columnName === g.columnName
                                );
                                if (combine_type) {
                                    combine_type.data =
                                        combine_type.data.concat(
                                            this.getColumnData(layer)
                                        );

                                    const id =
                                        click.id +
                                        '.' +
                                        (g ? g.name : layer.title);
                                    this.exportFeatures[id] =
                                        this.exportFeatures[id].concat(
                                            layer.features
                                        );
                                    combined = true;
                                }

                                g.columnName.split(',').forEach(title => {
                                    if (title === layer.title) mapData = g;
                                });
                            } else {
                                if (g.columnName === layer.title) {
                                    mapData = g;
                                }
                            }
                        });
                    }

                    if (combined) return;

                    // grid = {
                    //     id
                    //     columnNames
                    //     title
                    //     type: columns,piegraph,linegraph,bargraph
                    // }

                    // graph = {
                    // labels
                    // graph {
                    //      count
                    //      colors
                    // }
                    // }

                    info = new MatTableDataSource(this.getColumnData(layer));
                    info.rawData = this.getColumnData(layer);

                    if (mapData) {
                        if (
                            mapData.type === 'pie' ||
                            mapData.type === 'line' ||
                            mapData.type === 'bar'
                        ) {
                            info.title = mapData.name;
                            info.typeName = layer.title;
                            info.columnName = mapData.columnName;
                            info.id = click.id + '.' + mapData.name;
                            info.type = mapData.type;
                            info.labels = [];
                            info.graphInfo = mapData.graph;

                            info.columns = this.getColumnInfo(layer);
                            info.columnNames = info.columns.map(c => c.name);

                            const counting = {};
                            const count = [];

                            for (const currentObject of info.rawData) {
                                const result = currentObject[mapData.graph.x];
                                counting[result] = (counting[result] || 0) + 1;
                            }

                            count.push(...Object.values(counting));
                            info.labels.push(...Object.keys(counting));
                            info.labels.sort((a, b) => a - b);

                            info.graph = {
                                data: count,
                                backgroundColor: this.chartColors
                            };
                        } else {
                            info.title = mapData.name;
                            info.typeName = layer.title;
                            info.columnName = mapData.columnName;
                            info.id = click.id + '.' + mapData.name;
                            info.type = 'columns';

                            info.columnNames = mapData.columns.map(c => c.prop);

                            info.columns =
                                mapData.columns || this.getColumnInfo(layer);
                        }
                    } else {
                        // Fallback when there are no settings
                        info.title = layer.title;
                        info.typeName = layer.title;
                        info.id = click.id + '.' + layer.title;
                        info.type = 'columns';

                        const columnInfo = this.getColumnInfo(layer);
                        info.columnNames = columnInfo.map(c => c.name);

                        info.columns = columnInfo;
                    }
                } else {
                    // Settings for grid come from config, not used so much anymore
                    const configData = this.gridConfig.find(
                        obj => obj.columnName === layer.title
                    );
                    info.typeName = layer.title;
                    info.id = click.id + '.' + layer.title;
                    info.type = 'columns';

                    if (configData) {
                        info.title = configData.name;

                        info.columnNames = configData.columns.map(c => c.prop);

                        info.columns =
                            configData.columns || this.getColumnInfo(layer);
                    } else {
                        info.title = layer.title;

                        const columnInfo = this.getColumnInfo(layer);
                        info.columnNames = columnInfo.map(c => c.name);

                        info.columns = columnInfo;
                    }
                }

                this.exportFeatures[info.id] = layer.features;

                info.sort = this.sorts[index];
                if (click.layers.length) sortedFeatures.push(info);
            });
        });

        this.features = sortedFeatures;

        // hacky way to make sure the status will be set after setting the features
        setTimeout(() => {
            if (this.features?.length > 0) {
                this.gridService.openStatus.set('open');
            } else {
                this.gridService.openStatus.set('closed');
            }
        }, 0);
    }

    private getColumnInfo(
        layer: GridLayer
    ): { name: string; prop: string; width: number }[] {
        if (!layer.features.length) {
            return [];
        }

        const feature = layer.features[0];
        const props = feature.getProperties();

        const columnWidth = 30;
        const excludedProperties = new Set([
            'type',
            'msGeometry',
            'geometry',
            'resource',
            'boundedBy',
            'geom',
            'x',
            'y'
        ]);

        const columns = Object.keys(props)
            .filter(p => !excludedProperties.has(p))
            .map(p => ({
                name: p,
                prop: p,
                width: columnWidth,
                type: 'text'
            }));

        return columns;
    }

    private getColumnData(layer: GridLayer): any[] {
        const data = layer.features.map(feature => {
            const {
                msGeometry,
                geometry,
                boundedBy,
                x,
                y,
                geom,
                ...properties
            } = feature.getProperties();

            this.originalFeatures.push(feature);

            properties.id_ = feature.getId();

            return properties;
        });

        return data;
    }

    toggle() {
        if (this.gridService.openStatus() == 'open') {
            this.gridService.openStatus.set('closed');
        } else {
            this.gridService.openStatus.set('open');
        }
    }

    sortColumn(event) {
        this.cdr.detectChanges();
    }

    editTable(table) {
        const dia = this.dialog.open(EditGridDialog, {
            width: '65vw',
            height: '75vh',
            data: table
        });

        dia.afterClosed().subscribe(result => {
            if (result) {
                let i = this.features.findIndex(f => f.id === result.id);
                this.features[i] = result;

                // Search id and change grid
                // this.customGrids.push(result);
                this.cdr.detectChanges();
            }
        });
    }

    onTabChange(event) {
        this.gridService.activeTab = event.index;
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    OnDestroy,
    Output,
    QueryList,
    ViewChildren,
    EffectRef,
    effect,
    signal,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    computed
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
    LegendService,
    MapService,
    ConfigService,
    AuthService
} from 'app/_services';
import { environment } from 'environments/environment';
import { MapOpacitySliderComponent } from '../../_dialogs';
import { LegendGroupComponent } from '../group/group.component';
import { LegendLayerComponent } from '../layer/layer.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Options } from '@angular-slider/ngx-slider';
import { DeleteDialogComponent } from '../../_dialogs/delete/delete.dialog';
import { MetadataDialogComponent } from '../../_dialogs/metadata/metadata.dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import {
    faAngleDown,
    faAngleRight,
    faInfoCircle,
    faPaintBrush,
    faAdjust
} from '@fortawesome/free-solid-svg-icons';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';

interface Slider {
    value: number;
    options: Options;
}

@Component({
    selector: 'cook-map',
    templateUrl: 'map.component.html',
    styleUrls: ['map.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * There are two kinds of layers in OpenLayers and the LayerSwitcherComponent
 * The LayerGroup, which is a group of paramLayers that are contained within one actual Layer. Using the updateLayer
 * function we can change these so the paramLayers in the group are toggled on or off. These paramLayers are toggled
 * on or off by adding/removing them from the layer itself.
 */
export class LegendMapComponent implements OnInit, OnDestroy {
    readonly faAdjust = faAdjust;
    readonly faAngleDown = faAngleDown;
    readonly faAngleRight = faAngleRight;
    readonly faInfoCircle = faInfoCircle;
    readonly faPaintBrush = faPaintBrush;
    readonly faTimesCircle = faTimesCircle;

    readonly hide = signal(false);
    readonly broken = signal(false);
    readonly travel = signal(false);
    readonly checked = signal(false);
    readonly collapsed = signal(false);
    readonly hiddenByResolution = signal(false);

    readonly icon = signal(undefined);
    readonly label = signal(undefined);
    readonly tooltip = signal(undefined);
    readonly layersAndGroups = signal([]);
    readonly isCollapsable = computed(() => {
        return this.layersAndGroups().some(layer => {
            if (layer?.layers?.length > 0) {
                const hasVisibleLayers = layer.layers.some(l => {
                    return l.hide_in_legend === false;
                });

                return hasVisibleLayers;
            }

            return layer.hide_in_legend === false;
        });
    });

    sliderOptions: Slider;

    private _enableLayerSubscription: Subscription;
    private readonly resolutionSubscription: EffectRef;

    @ViewChildren(LegendLayerComponent)
    readonly layers: QueryList<LegendLayerComponent>;

    @ViewChildren(LegendGroupComponent)
    readonly groups: QueryList<LegendGroupComponent>;

    @Output() readonly checkEvent = new EventEmitter<boolean>();

    @Input() readonly map: any;

    constructor(
        readonly authService: AuthService,
        readonly configService: ConfigService,
        private readonly mapService: MapService,
        private readonly http: HttpClient,
        private readonly dialog: MatDialog,
        private readonly snackBar: MatSnackBar,
        private readonly sanitizer: DomSanitizer,
        private readonly legendService: LegendService
    ) {
        this.resolutionSubscription = effect(
            () => {
                const resolution = this.mapService.resolution();

                if (
                    (this.map?.maxResolution || this.map?.minResolution) &&
                    resolution
                ) {
                    const hiddenByResolution =
                        (this.map.maxResolution &&
                            resolution > this.map.maxResolution) ||
                        (this.map.minResolution &&
                            resolution < this.map.minResolution)
                            ? true
                            : false;

                    this.hiddenByResolution.set(hiddenByResolution);
                }
            },
            { allowSignalWrites: true }
        );
    }

    ngOnInit(): void {
        // Watch the map resolution if the resolution is set
        // Check the legend if the map has changed, this is only to show it is checked
        this._enableLayerSubscription =
            this.legendService.enableLayerChange.subscribe(layerName => {
                if (layerName === this.label() && !this.map.visible) {
                    this.checked.set(true);

                    this.layersAndGroups().forEach(layer => {
                        layer.visible = true;
                    });

                    if (!this.collapsed()) {
                        this.layers.forEach(layer => layer.checked.set(true));
                        this.groups.forEach(group => group.checked.set(true));
                    }
                }
            });

        const stepsArray = [];

        this.map.source.layers.forEach((l, i) => {
            stepsArray.push({ value: i, legend: l.label });
        });

        this.sliderOptions = {
            value: 0,
            options: {
                // showTicksValues: true,
                hideLimitLabels: true,
                rightToLeft: true,
                floor: 0,
                ceil: this.map.source.layers.length,
                showTicks: true,
                vertical: true,
                stepsArray
                // bindIndexForStepsArray: true
            }
        };

        this.initMap();
    }

    ngOnDestroy(): void {
        this._enableLayerSubscription.unsubscribe();
        this.resolutionSubscription.destroy();
    }

    private initMap(): void {
        this.label.set(this.map.name);
        this.collapsed.set(this.map.collapsed);
        this.tooltip.set(this.map.tooltip);
        this.broken.set(this.map.broken);
        this.travel.set(this.map.travel);

        this.hide.set(this.map.hide);

        if (this.map.icon) {
            this.icon.set(
                this.sanitizer.bypassSecurityTrustHtml(this.map.icon)
            );
        }

        let hasCheckedLayer = false;
        let hasCheckedGroupLayer = false;

        hasCheckedLayer = this.map.source.layers.some(l => l.visible);

        // this.map.source.layers = this.map.source.layers.sort(
        //     (n1, n2) => n1.sort - n2.sort
        // );

        hasCheckedGroupLayer = this.map.source.layer_groups.some(
            l => l.visible
        );

        // this.map.source.layer_groups = this.map.source.layer_groups.sort(
        //     (n1, n2) => n1.sort - n2.sort
        // );

        const shouldCheck = hasCheckedLayer || hasCheckedGroupLayer;

        if (shouldCheck) {
            this.map.visible = shouldCheck;
            this.checked.set(shouldCheck);
        } else {
            this.checked.set(this.map.visible);
        }

        // const isVisible = this.legendService.isMapVisible(this.map);

        // if (this.map.visible !== isVisible) {
        //     this.legendService.toggleMap(this.map);
        // }

        const mergedLayers = [
            ...this.map.source.layers,
            ...this.map.source.layer_groups
        ].sort((a, b) => {
            const aSort = a?.sort ?? a.order ?? 0; // fallback to 'order' or 0 if 'sort' is null or undefined
            const bSort = b?.sort ?? b.order ?? 0;
            return aSort - bSort;
        });

        this.layersAndGroups.set(mergedLayers);
    }

    toggleMap(): void {
        this.map.visible = !this.map.visible;

        const isVisible = this.legendService.isMapVisible(this.map);

        if (this.map.visible !== isVisible) {
            this.legendService.toggleMap(this.map);
        }

        this.layersAndGroups().forEach(item => {
            const isGroup = item.layers ? true : false;

            if (isGroup) {
                this.processGroup(item);
            } else {
                this.toggleLayerIfNeeded(item);
            }
        });

        this.updateCustomMap();

        this.checkEvent.emit(this.map.visbible);
    }

    viewChildChecked(checked: boolean): void {
        if (checked) {
            this.checked.set(true);
            this.map.visible = true;
            const isVisible = this.legendService.isMapVisible(this.map);

            if (this.map.visible !== isVisible) {
                this.legendService.toggleMap(this.map);
            }
        } else if (!checked && this.map.visible) {
            this.uncheckMapIfNeeded();
        }

        const hasCheckedLayers = this.map.source?.layers.some(
            layer => layer.visible
        );

        const hasCheckedGroups = this.map.source?.layer_groups.some(
            group => group.visible
        );

        const newValue = hasCheckedLayers || hasCheckedGroups;

        this.checked.set(newValue);
        this.map.visible = newValue;

        this.updateCustomMap();

        this.checkEvent.emit(this.map.visbible);
    }

    private uncheckMapIfNeeded(): void {
        const hasCheckedLayers = this.map.source?.layers.some(
            layer => layer.visible
        );

        const hasCheckedGroups = this.map.source?.layer_groups.some(
            group => group.visible
        );

        const newValue = hasCheckedLayers || hasCheckedGroups;
        this.checked.set(newValue);
        this.map.visible = newValue;

        const isVisible = this.legendService.isMapVisible(this.map);

        if (this.map.visible !== isVisible) {
            this.legendService.toggleMap(this.map);
        }
    }

    private processGroup(group: any): void {
        group.visible = this.map.visible;

        const isVisible = this.legendService.isGroupVisible(group, this.map);

        if (group.visible !== isVisible) {
            this.legendService.toggleLayer(group, this.map);
        }

        group.layers.forEach(l => {
            l.visible = group.visible;

            const isVisible = this.legendService.isLayerVisible(l, this.map);

            if (l.visible !== isVisible) {
                this.legendService.toggleLayer(l, this.map);
            }
        });

        if (this.groups.length === 0) {
            return;
        }

        const groupComponent =
            this.groups.find(g => g.group.name === group.name) ??
            this.groups.find(g => g.group.layerName === group.layerName);

        if (groupComponent) {
            groupComponent.checked.set(groupComponent.group.visible);

            if (groupComponent.childLayers.length !== 0) {
                groupComponent.childLayers.forEach(l => {
                    l.checked.set(groupComponent.group.visible);
                });
            }
        }
    }

    private toggleLayerIfNeeded(layer: any): void {
        if (layer.name && !this.collapsed()) {
            const layerComponent = this.layers.find(
                l => l.name() === layer.name
            );

            if (
                layerComponent &&
                layerComponent?.layer.visible !== this.map.visible
            ) {
                layerComponent.toggleLayer();
                layerComponent.checked.set(layerComponent.layer.visible);
            }

            return;
        } else if (layer.name && this.collapsed()) {
            layer.visible = this.map.visible;

            const isVisible = this.legendService.isLayerVisible(
                layer,
                this.map
            );

            if (layer.visible !== isVisible) {
                this.legendService.toggleLayer(layer, this.map);
            }
        }

        layer.visible = this.map.visible;
    }

    /**
     * Collapse the layers
     */
    toggleCollapse(): void {
        this.collapsed.set(!this.collapsed());
        this.map.collapsed = this.collapsed();
        this.updateCustomMap();
    }

    changeOpacity(olMap): void {
        // Open the modal with the opacity slider
        this.snackBar.openFromComponent(MapOpacitySliderComponent, {
            data: { olMap },
            panelClass: 'white-snackbar',
            verticalPosition: 'top'
        });
    }

    openStyleDialog(map): void {
        this.legendService.styleMap.set(map);

        if (map.styling) {
            this.legendService.styleSettings = map.styling;
        } else {
            this.legendService.styleSettings = {
                color: '#f49441',
                lineWidth: 0,
                fillColor: 'rgba(244, 148, 65, 0.3)',
                labelKey: '',
                labelMaxRes: 50
            };
        }

        // Get the keys from the first feature of the layer

        const features =
            this.legendService.findMap(map.id).getSource()?.getFeatures() ?? [];

        if (features.length > 0) {
            const keys = features[0].getKeys();

            keys.filter(value => {
                if (value !== 'geometry') {
                    return value;
                }
            });
            this.legendService.styleLabelKeys.set(keys);
        }
    }

    /**
     * Triggers when the checkbox is checked by a user and emits the event to the parent
     */
    checkboxChanged(event: Event): void {
        // If this item is checked now, emit an event so the map knows and can check accordingly
        this.checked.set(this.map.visible);
        this.checkEvent.emit(this.map.visible);
    }

    drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(
            this.layersAndGroups(),
            event.previousIndex,
            event.currentIndex
        );

        this.layersAndGroups().forEach((m, i) => {
            if (m.sort !== i) {
                m.sort = i;
            }
        });

        this.updateCustomMap();
    }

    openMetadata(): void {
        // open the delete dialog to confirm the delete
        this.dialog.open(MetadataDialogComponent, {
            data: {
                descriptor: this.map.name,
                metadata: JSON.parse(this.map.metadata)
            }
        });
    }

    deleteMap(): void {
        // open the delete dialog to confirm the delete
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            data: {
                id: this.map.id,
                type: 'kaart',
                descriptor: this.map.name
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result.id) {
                this.http
                    .post(
                        `${environment.api_base_url}/maps/remove-map-from-config`,
                        {
                            map_id: this.map.id,
                            config_id: this.configService.config().id
                        }
                    )
                    .toPromise()
                    .then(res => {
                        this.configService.loadConfiguration(
                            this.configService.config().id
                        );
                    })
                    .catch(err => {
                        console.error(err);
                    });
            } else {
                console.error('Dialog closed, result: ' + result);
            }
        });
    }

    sliderChange(event): void {
        const currentLayer = this.map.source.layers[event.value];

        // Turn on the map
        if (!this.checked()) {
            this.map.visible = true;
            this.checked.set(true);
            this.legendService.toggleMap(this.map);
        }

        // Disable all the layers
        this.layersAndGroups().forEach(layer => {
            if (layer.visible) {
                this.legendService.toggleLayer(layer, this.map);
            }
            layer.visible = false;
        });

        // Enable the selected layer
        currentLayer.visible = true;
        this.legendService.toggleLayer(currentLayer, this.map);
    }

    private updateCustomMap(): void {
        this.legendService.customMap[this.map.name] = this.map;
    }
}

import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as localforage from 'localforage';
import { environment } from 'environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    readonly role = signal(undefined);
    readonly configId = signal(undefined);
    readonly organization = signal(undefined);

    readonly id = signal(undefined);
    readonly name = signal(undefined);
    readonly redirectUrl = signal(undefined);
    readonly configurations = signal(undefined);

    constructor(
        private readonly http: HttpClient,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute
    ) {
        this.id.set(localStorage.getItem('id'));
        this.name.set(localStorage.getItem('name'));
        this.organization.set(localStorage.getItem('organization'));
        this.role.set(localStorage.getItem('role'));
        this.configId.set(localStorage.getItem('configId'));
        localforage.getItem('configurations').then((value: any) => {
            this.configurations.set(value);
        });

        // Check if the user is still logged in when the window gains focus
        window.addEventListener('focus', () => {
            if (document.hasFocus()) {
                this.loggedIn();
            }
        });
    }

    setDetails({ user, organization, config_id, configurations }): void {
        if (user?.id) {
            this.id.set(user.id);
            localStorage.setItem('id', this.id());
        }

        if (user?.name) {
            this.name.set(user.name);
            localStorage.setItem('name', this.name());
        }

        if (organization?.name) {
            this.organization.set(organization.name);
            localStorage.setItem('organization', this.organization());
        }

        if (user?.role?.name) {
            this.role.set(user.role.name);
            localStorage.setItem('role', this.role());
        }

        if (config_id) {
            this.configId.set(config_id);
            localStorage.setItem('configId', this.configId());
        }

        if (configurations) {
            this.configurations.set(configurations);
            localforage.setItem('configurations', configurations);
        }

        const now = Date.now();
        localStorage.setItem('lastLoginCheck', now.toString());
    }

    hasPermission(allowedRoles: string | string[]): boolean {
        if (!Array.isArray(allowedRoles)) {
            allowedRoles = [allowedRoles];
        }

        const role = this.role();

        return Boolean(role && allowedRoles.some(p => p === role));
    }

    /**
     * Check if the session has expired
     * @return {boolean} [description]
     */
    loggedIn(): void {
        this.http
            .get(environment.api_base_url + '/loggedin', {
                responseType: 'json',
                observe: 'response'
            })
            .subscribe((response: any) => {
                // If it returns an unauthorised response we get logged out automatically
                if (response.status != 200) {
                    this.logout();
                }
            });
    }

    logout(): void {
        this.http
            .get(environment.api_base_url + '/logout', {
                observe: 'response',
                responseType: 'json'
            })
            .subscribe((response: any) => {
                this.id.set(undefined);
                this.name.set(undefined);
                this.organization.set(undefined);
                this.configId.set(undefined);
                this.role.set(undefined);
                this.configurations.set(undefined);

                localStorage.clear();
                localforage.clear();
                this.router.navigate(['/login']);
            });
    }

    loadApp(): void {
        console.log('load app');
        const elementId = this.activatedRoute.snapshot.paramMap.get('element');
        if (elementId) {
            this.router.navigate(['/element', elementId]);
            return;
        }
        if (this.redirectUrl() && this.redirectUrl() !== '/login') {
            this.router.navigate([this.redirectUrl()]);
            return;
        }
        this.router.navigate([''], { replaceUrl: true });
    }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ConfigService, AuthService } from 'app/_services';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import {
    faPlus,
    faPencil,
    faMousePointer,
    faVectorSquare,
    faTimes,
    faMapMarkedAlt,
    faStreetView,
    faDove,
    faExpandArrowsAlt,
    faArrowLeft,
    faArrowRight,
    faCrosshairs,
    faCircle,
    faSearchLocation,
    faMapPin,
    faGlobe,
    faFilter,
    faInfoCircle,
    faSave
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-manual',
    templateUrl: './user-manual.dialog.html',
    styleUrls: ['./user-manual.dialog.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * Manual for the users to get instructions of how to use specific features
 */
export class UserManualDialogComponent {
    title = '';
    legendaURL = '';

    readonly environment = environment;
    readonly faPlus = faPlus;
    readonly faPencil = faPencil;
    readonly faMousePointer = faMousePointer;
    readonly faVectorSquare = faVectorSquare;
    readonly faDrawCircle = faCircle;
    readonly faTimes = faTimes;
    readonly faMapMarkedAlt = faMapMarkedAlt;
    readonly faStreetView = faStreetView;
    readonly faDove = faDove;
    readonly faExpandArrowsAlt = faExpandArrowsAlt;
    readonly faArrowLeft = faArrowLeft;
    readonly faArrowRight = faArrowRight;
    readonly faCrosshairs = faCrosshairs;
    readonly faCircle = faCircle;
    readonly faSearchLocation = faSearchLocation;
    readonly faMapPin = faMapPin;
    readonly faGlobe = faGlobe;
    readonly faFilter = faFilter;
    readonly faInfoCircle = faInfoCircle;
    readonly faSave = faSave;

    constructor(
        readonly configService: ConfigService,
        readonly authService: AuthService,
        private readonly dialogRef: MatDialogRef<UserManualDialogComponent>
    ) {
        window.addEventListener('scroll', this.scrollChanged);

        switch (environment.loginPrefix) {
            case 'gis_': {
                this.title = 'Handleiding GISportaal Nederland';

                if (this.environment.public == true) {
                    this.legendaURL = `/assets/img/handleiding/legenda-public.png`;
                } else {
                    this.legendaURL = `/assets/img/handleiding/legenda.png`;
                }

                break;
            }

            default: {
                this.title = 'Handleiding GISportaal';

                if (this.environment.public == true) {
                    this.legendaURL = `/assets/img/handleiding/legenda-public.png`;
                } else {
                    this.legendaURL = `/assets/img/handleiding/legenda.png`;
                }

                break;
            }
        }
    }

    back(): void {
        this.dialogRef.close();
    }

    scrollChanged(): void {
        const y = window.scrollY;

        if (y == 0) {
            document.getElementById('scrollToTop').style.display = 'none';
        } else {
            document.getElementById('scrollToTop').style.display = 'block';
        }
    }
}

import { Component } from '@angular/core';
import {
    MapService,
    InteractionService,
    SidenavService,
    EditService,
    LayerService,
    DrawService,
    DetailService,
    AuthService,
    ConfigService,
    LegendService
} from 'app/_services/';
import { faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Select, Modify, Translate, Snap } from 'ol/interaction';
import { shiftKeyOnly, singleClick } from 'ol/events/condition';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import { FormService } from 'app/_services/form.service';

@Component({
    selector: 'edit',
    templateUrl: 'edit.component.html',
    styleUrls: ['edit.component.scss']
})
/**
 * Tool for editing layers
 * On the right of the Map
 */
export class EditComponent {
    readonly faPencil = faPencil;
    readonly faPlus = faPlus;
    environment = environment;

    constructor(
        public readonly snackBar: MatSnackBar,
        public readonly authService: AuthService,
        public readonly interactionService: InteractionService,
        public readonly configService: ConfigService,
        public readonly formService: FormService,
        private readonly mapService: MapService,
        private legendService: LegendService,
        private readonly editService: EditService,
        private readonly drawService: DrawService,
        private readonly layerService: LayerService,
        private readonly detailService: DetailService,
        private readonly sidenavService: SidenavService
    ) {}

    /**
     * Add the singleclick event to the map and load the edit function when a feature is clicked that is editable
     * On the right of the Map
     */
    editFeature(layer): void {
        this.detailService.features.set(undefined);
        const edit = this.interactionService.edit;

        this.layerService.drawingLayer().getSource().clear();

        if (this.interactionService.editLayers.length <= 1) {
            if (edit.active) {
                this.interactionService.removeInteractions();
            } else {
                this.interactionService.removeInteractions();
                edit.active = !edit.active;
            }
        } else {
            this.interactionService.removeInteractions();
            edit.active = !edit.active;
        }

        if (edit.active) {
            this.sidenavService.tabIndex.set(0);
            this.selectFromLayer(layer);
        } else {
            this.editService.setfeatureType(undefined);
            this.sidenavService.tabIndex.set(0);
        }
    }

    selectFromLayer(layer): void {
        const info = this.interactionService.info;
        // Only select from layers in the selectFromLayers configuration
        info.interaction = new Select({
            style: this.drawService.styles['yellow'],
            layers: [layer]
        });

        // Set layer and feature so the editservice can delegate the sidenav
        info.interaction.on('select', (event, target) => {
            // If an item is selected add it
            if (event.selected.length) {
                this.editService.setfeatureType(layer.get('featureType'));
                this.sidenavService.tabIndex.set(1);

                // Bad coding but we need to wait till the sidenav is done collapsing the tab group
                setTimeout(() => {
                    this.editService.layer.set(layer);

                    // Get the first selected item and send it to the editService
                    this.editService.feature.set(event.selected[0]);

                    // Set featureType from the features id
                    this.editService.setfeatureType(layer.get('featureType'));
                }, 20);
            } else {
                this.snackBar.open('Geen element gevonden', '', {
                    duration: 3000,
                    panelClass: 'white-snackbar',
                    verticalPosition: 'top'
                });
            }
        });

        const map = this.mapService.map;

        map().addInteraction(info.interaction);

        // grab the features from the select interaction to use in the modify interaction
        const selected_features = info.interaction.getFeatures();

        // Now allow the user to change the selected vector and activate the interaction tool
        this.interactionService.edit.interaction = new Modify({
            features: selected_features,
            style: this.drawService.styles['yellow'],
            // delete vertices by pressing the SHIFT key
            deleteCondition: event => shiftKeyOnly(event) && singleClick(event)
        });

        this.interactionService.translate.interaction = new Translate({
            features: selected_features
        });

        map().addInteraction(this.interactionService.edit.interaction);

        this.interactionService.snap.interaction = new Snap({
            source: layer.getSource()
        });

        map().addInteraction(this.interactionService.snap.interaction);
    }

    editForm() {
        this.detailService.features.set(undefined);
        const edit = this.interactionService.edit;

        const layer = this.legendService.findMap(
            'form_' + this.formService.form.id
        );

        if (edit.active) {
            this.interactionService.removeInteractions();
        } else {
            this.interactionService.removeInteractions();
            edit.active = !edit.active;
        }

        if (edit.active) {
            const info = this.interactionService.info;
            // Only select from layers in the selectFromLayers configuration
            info.interaction = new Select({
                style: this.drawService.styles['yellow'],
                layers: [layer]
            });

            info.interaction.on('select', (event, target) => {
                this.sidenavService.tabIndex.set(1);
                // If an item is selected add it
                if (event.selected.length) {
                    // Bad coding but we need to wait till the sidenav is done collapsing the tab group
                    setTimeout(() => {
                        this.editService.layer.set(layer);

                        // Get the first selected item and send it to the editService
                        this.editService.feature.set(event.selected[0]);
                    }, 20);
                } else {
                    this.snackBar.open('Geen element gevonden', '', {
                        duration: 3000,
                        panelClass: 'white-snackbar',
                        verticalPosition: 'top'
                    });
                }
            });

            const map = this.mapService.map;

            map().addInteraction(info.interaction);

            // grab the features from the select interaction to use in the modify interaction
            const selected_features = info.interaction.getFeatures();

            // Now allow the user to change the selected vector and activate the interaction tool
            this.interactionService.edit.interaction = new Modify({
                features: selected_features,
                style: this.drawService.styles['yellow'],
                // delete vertices by pressing the SHIFT key
                deleteCondition: event =>
                    shiftKeyOnly(event) && singleClick(event)
            });

            this.interactionService.translate.interaction = new Translate({
                features: selected_features
            });

            map().addInteraction(this.interactionService.edit.interaction);
            map().addInteraction(this.interactionService.translate.interaction);

            // this.interactionService.snap.interaction = new Snap({
            //     source: layer.getSource()
            // });

            // map().addInteraction(this.interactionService.snap.interaction);
        } else {
            this.editService.setfeatureType(undefined);
            this.sidenavService.tabIndex.set(0);
        }
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from 'environments/environment';
import { ConfigService } from './config.service';
import { MapService } from './map.service';
import { Subject } from 'rxjs';
import * as OlSource from 'ol/source';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
/**
 * This service handles legend actions
 */
export class LegendService {
    readonly environment = environment;
    customMap: any = {};
    customMapGroup: any = {};

    readonly dynamicLegend = signal(false);

    readonly enableLayerChange: Subject<string> = new Subject();
    legendUrls: Array<any>;

    readonly styleLabelKeys = signal<string[]>([]);
    readonly styleMap = signal(undefined);
    styleSettings = {
        color: '#f49441',
        lineWidth: 0,
        fillColor: 'rgba(244, 148, 65, 0.3)',
        labelKey: '',
        labelMaxRes: 50
    };
    readonly imageUrls = signal([]);
    constructor(
        private readonly mapService: MapService,
        private readonly configService: ConfigService,
        private readonly http: HttpClient,
        private readonly sanitizer: DomSanitizer,
        private readonly snackBar: MatSnackBar
    ) {}

    /**
     * Toggles an entire map on/off
     * @param map [description]
     */
    toggleMap(map): void {
        const olMap = this.findMap(map?.id);

        if (olMap) {
            olMap.setVisible(!olMap.getVisible());
        }
    }

    /**
     * Checks if a map is visible or not
     * @param  map The map to check
     * @return     [description]
     */
    isMapVisible(map): boolean {
        const olMap = this.findMap(map?.id);

        if (olMap) {
            return olMap.getVisible();
        } else {
            console.error('Map not found');
            return false;
        }
    }

    isLayerVisible(layer, map): boolean {
        const olMap = this.findMap(map?.id);

        if (!olMap) {
            console.error('Map not found');
            return false;
        }

        const olMapSource = olMap.getSource();

        if (!olMapSource) {
            console.error('Map source not found');
            return false;
        }

        if (olMapSource instanceof OlSource.Cluster) {
            return;
        }

        if (olMapSource instanceof OlSource.Vector) {
            const visibleLayers = map?.source?.layers ?? [];

            const isLayerVisible = visibleLayers.some(
                lyr => lyr.name === layer.name && lyr.visible === true
            );

            return isLayerVisible;
        } else {
            const olMapParams = olMapSource.getParams();

            return olMapParams.LAYERS.includes(layer.name);
        }
    }

    isGroupVisible(group, map): boolean {
        const olMap = this.findMap(map?.id);

        if (olMap) {
            const olMapSource = olMap.getSource();

            if (olMapSource instanceof OlSource.Cluster) {
                // A cluster cannot have layers
                return;
            } else {
                const olMapParams = olMapSource.getParams();

                return olMapParams.LAYERS.includes(group.layerName);
            }
        } else {
            console.error('Map not found');
            return false;
        }
    }

    /**
     * Turns a layer on/off on the map by adding/removing it to/from the params
     * @param layer The layer to toggle
     * @param map   The map in which the layer resides
     */
    toggleLayer(layer: any, map: any): void {
        const olMap = this.findMap(map.id);

        // Get the layer name, needed because this can be a group in which case the 'layerName' property
        // is the one we want to use as the name of the layer ('name' is the name of the group)
        const layerName = layer.layerName ?? layer.name;

        // Get the params and add or remove this layer from it, in the right position
        const olMapSource = olMap.getSource();

        let olMapParams;
        let existingParam;
        let layerArray;

        if (
            olMapSource instanceof OlSource.Cluster ||
            olMapSource instanceof OlSource.VectorTile ||
            olMapSource instanceof OlSource.Vector
        ) {
            // A cluster cannot have layers
            return;
        } else {
            try {
                olMapParams = olMapSource.getParams();

                layerArray = olMapParams.LAYERS;
                existingParam = layerArray.find(p => p === layerName);
            } catch (error) {
                // Fallback for WMTS layers
                if (!olMapParams) {
                    olMapParams = olMap.getSource();
                    layerArray = olMapParams.layer_;
                    existingParam = layerArray.find(p => p === layerName);
                }
            }
        }

        // If a param exists, remove it, otherwise add the param and reorder the params
        if (existingParam) {
            // Remove it
            layerArray.splice(layerArray.indexOf(existingParam), 1);

            // Reorder, only if we have more than 1 param
            if (layerArray.length > 1) {
                layerArray = this.orderMapParams(map, layerArray);
            }
        } else {
            // Add it, then reorder
            layerArray.push(layerName);

            // Reorder, only if we have more than 1 param
            if (layerArray.length > 1) {
                layerArray = this.orderMapParams(map, layerArray);
            }
        }

        if (olMapParams.LAYERS) {
            olMapParams.LAYERS = layerArray;

            // Trigger the source to let it know it's been updated
            olMapSource.updateParams(olMapParams);
        } else {
            olMapSource.set('layer_', layerArray);
            olMapSource.refresh();
            olMapSource.changed();
        }

        olMapSource.dispatchEvent('change');
    }

    /**
     * Send out a change to enable a layer if it isn't enabled yet
     *
     * @param      layerName  The layer name
     */
    enableLayer(layerName: string): void {
        this.enableLayerChange.next(layerName);
    }

    /**
     * Find a map in the layers of the entire map and return it
     * @param  id The id of the map to find
     * @return     [description]
     */
    findMap(id): any {
        // Find this map in the map layers
        const olMaps = this.mapService.map().getLayers().getArray();

        return olMaps.find(m => m.get('id') === id);
    }

    findLayer(id: number): any {
        const olMaps = this.mapService.map().getLayers().getArray();
        let layer;
        olMaps.forEach(m => {
            if (m.get('id') === id) {
                layer = m;
            }
        });

        return layer;
    }

    /**
     * Order the params for the given layer, so they have the same order
     * as the one that was set in the config
     * @TODO: check if we can't make use of array[param.order] to order them automatically, without the need for reordering
     * @param map               The map object from our config
     * @param olMapParamsLayers The layers from the map object
     */
    private orderMapParams(map: any, olMapParamsLayers: any): string[] {
        // Now sort the params according to the order in the configLayer
        let paramLayers = map.source.layers;

        // If there are groups in the source, combine them with the normal layers
        if (map.source.layer_groups && map.source.layer_groups.length > 0) {
            paramLayers = this.combineParamLayers(map);
        }

        const sortedParamLayers = paramLayers.sort(
            (n1, n2) => n1.order - n2.order
        );

        const orderedParamLayers = [];

        for (let i = 0; i < sortedParamLayers.length; i++) {
            // Check if this param is enabled, make sure we check for layerName if it exists (for groups), otherwise use the name property (for layers)
            const layerName = sortedParamLayers[i].layerName
                ? sortedParamLayers[i].layerName
                : sortedParamLayers[i].name;
            if (olMapParamsLayers.includes(layerName)) {
                orderedParamLayers.push(layerName);
            }
        }

        return orderedParamLayers;
    }

    /**
     * Takes a layer and returns a list of all paramLayers (doesn't matter if they belong to a group)
     * @param  map   The map of which all layers have to be combined (so we can use the paramLayers)
     * @return       All layers combined
     */
    private combineParamLayers(map): any {
        const paramLayers = map.source.layers;
        const paramLayersOfGroups = map.source.layer_groups.map(g => {
            // This group functions as a layer, return it
            if (g.layerName) {
                return g;
            }

            // return g.layers.find(l => l.name != null);
            return g.layers.filter(l => l.name !== undefined);
        });

        // paramLayersOfGroups now consists of arrays of layers and groups with a layer name
        // This has to be merged, which is done below into the tempArray
        let tempArray = [];
        for (const l of paramLayersOfGroups) {
            // Merge everything into the tempArray
            if (Array.isArray(l)) {
                tempArray = tempArray.concat(l);
            } else {
                tempArray.push(l);
            }
        }

        return paramLayers.concat(tempArray);
    }

    /**
     * Save the legend settings for this user
     */
    saveLegendSettings(): void {
        const settings: any = {};

        // Set option_id so we can save these settings for this specific config
        settings['option_id'] = this.configService.config().id;

        settings['maps'] = this.customMap;
        settings['mapGroups'] = this.customMapGroup;

        this.http
            .post(`${this.environment.api_base_url}/legend`, settings, {
                responseType: 'json'
            })
            .subscribe(r => {
                if (r === 'succes') {
                    this.snackBar.open(
                        'Legenda is succesvol opgeslagen',
                        'Ok',
                        {
                            duration: 3500,
                            panelClass: 'white-snackbar'
                        }
                    );
                } else {
                    this.snackBar.open(
                        'Er is iets misgegaan bij het opslaan van de legenda',
                        'Ok',
                        {
                            duration: 3500,
                            panelClass: 'white-snackbar'
                        }
                    );
                }
            });
    }

    private createLegendUrl(url: string, serverType: string): string {
        const cleanUrl = url
            .replace(/\s/g, '')
            .replace('getlegendgraphic', 'GetLegendGraphic');
        const urlObject = new URL(cleanUrl);

        if (urlObject.searchParams.has('legend_options')) {
            urlObject.searchParams.set(
                'legend_options',
                'hideEmptyrules:true;forcelabels:on'
            );
        }

        // this is a fallback for if the serverType isnt set in the database
        serverType =
            serverType ||
            (url.includes('mapserver')
                ? 'mapserver'
                : url.includes('service.pdok')
                ? 'pdok'
                : 'geoserver');

        if (
            !urlObject.searchParams.has('crs') &&
            !urlObject.searchParams.has('srs')
        ) {
            urlObject.searchParams.set('srs', this.getProjectionCode());
        }

        if (!urlObject.searchParams.has('bbox')) {
            urlObject.searchParams.set('bbox', this.getExtent().join(','));
        }

        if (serverType == 'mapserver') {
            if (!urlObject.searchParams.has('width')) {
                urlObject.searchParams.set('width', '20000');
            }

            if (!urlObject.searchParams.has('height')) {
                urlObject.searchParams.set('height', '20000');
            }
        } else if (serverType == 'pdok') {
            if (!urlObject.searchParams.has('width')) {
                urlObject.searchParams.set('width', '4000');
            }

            if (!urlObject.searchParams.has('height')) {
                urlObject.searchParams.set('height', '4000');
            }
        } else if (serverType == 'geoserver') {
            if (!urlObject.searchParams.has('width')) {
                urlObject.searchParams.delete('width');
            }

            if (!urlObject.searchParams.has('height')) {
                urlObject.searchParams.delete('height');
            }
        }

        return urlObject.toString();
    }

    private getExtent() {
        return this.mapService.map().getView().calculateExtent();
    }

    private getProjectionCode() {
        return this.mapService.map().getView().getProjection().getCode();
    }

    createDynamicLegend(): void {
        this.imageUrls.set([]);

        const srs = this.getProjectionCode();
        const bbox = this.getExtent().join(',');
        const res = this.mapService.map().getView().getResolution();

        const visibleLayers = this.mapService
            .map()
            .getLayers()
            .getArray()
            .filter(
                layer =>
                    layer.getVisible() &&
                    !layer.get('background') &&
                    layer.getMinResolution() < res &&
                    res < layer.getMaxResolution()
            );

        const processedUrls = new Set<string>();

        for (const layer of visibleLayers) {
            const source = layer.get('source');

            if (
                source instanceof OlSource.ImageWMS ||
                source instanceof OlSource.TileWMS
            ) {
                const serverType = layer.get('serverType');
                const params = source.getParams();
                let legendUrls: string[] = [];

                if (params?.LAYERS?.length > 0) {
                    const format = params?.FORMAT ?? 'image/png';
                    const version = params?.VERSION ?? '1.3.0';

                    for (const l of params?.LAYERS) {
                        let legendLayerUrl = `${
                            layer.get('source').url_
                        }&request=getlegendgraphic&format=${format}&service=wms&version=${version}&layer=${l}&srs=${srs}&bbox=${bbox}&legend_options=hideEmptyrules:true;forcelabels:on`.replace(
                            /\s/g,
                            ''
                        );

                        legendLayerUrl = this.createLegendUrl(
                            legendLayerUrl,
                            serverType
                        );

                        // Check if the URL has already been processed
                        if (!processedUrls.has(legendLayerUrl)) {
                            processedUrls.add(legendLayerUrl);
                            legendUrls.push(legendLayerUrl);
                        }
                    }
                } else {
                    const legendUrl = source?.getLegendUrl(res, params);

                    if (legendUrl) {
                        const processedUrl = this.createLegendUrl(
                            legendUrl,
                            serverType
                        );

                        // Check if the URL has already been processed
                        if (!processedUrls.has(processedUrl)) {
                            processedUrls.add(processedUrl);
                            legendUrls.push(processedUrl);
                        }
                    }
                }

                // Create a new legendLayer object for each layer
                const legendLayer = {
                    name: layer.get('title'),
                    icons: []
                };

                // Send requests for each unique legend URL
                for (const legendUrl of legendUrls) {
                    this.sendLegendImgRequest(
                        legendUrl,
                        { ...legendLayer },
                        layer
                    );
                }
            }
        }
    }

    private sendLegendImgRequest(
        url: string,
        legendLayer: any,
        layer: any
    ): void {
        const auth = layer?.get('authorization');
        const headers = auth ? { Authorization: auth } : {};

        this.http.get(url, { responseType: 'arraybuffer', headers }).subscribe({
            next: response => {
                if (response.byteLength <= 99) return;

                const data = new Uint8Array(response);
                const base64 = btoa(String.fromCharCode(...data));
                const src = 'data:image;base64,' + base64;
                const imageUrl = this.sanitizer.bypassSecurityTrustUrl(src);
                const layerName = layer?.values_?.title;

                if (!layerName) return;

                // Get the current value of imageUrls
                const currentImageUrls = this.imageUrls() || [];

                // Create a new array and copy the current imageUrls
                const newImageUrls = [...currentImageUrls];

                const existingLayerIndex = newImageUrls.findIndex(
                    i => i.name.toLowerCase() === layerName?.toLowerCase()
                );

                if (existingLayerIndex > -1) {
                    const existingIconIndex = newImageUrls[
                        existingLayerIndex
                    ].icons.findIndex(
                        icon => icon.toString() === imageUrl.toString()
                    );
                    if (existingIconIndex === -1) {
                        // Create a new icons array to avoid mutating the original array
                        const updatedIcons = [
                            ...newImageUrls[existingLayerIndex].icons,
                            imageUrl
                        ];
                        newImageUrls[existingLayerIndex] = {
                            ...newImageUrls[existingLayerIndex],
                            icons: updatedIcons
                        };
                    }
                } else {
                    newImageUrls.push({
                        name: legendLayer.name,
                        icons: [imageUrl]
                    });
                }

                // Update the signal with the new array
                this.imageUrls.set(newImageUrls);
            },
            error: err => {
                // Handle error appropriately
                console.error(err);
            }
        });
    }

    trackByIcon(index: number, item: any): any {
        return item ? item.key : undefined;
    }
}
